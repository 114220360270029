import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import { useFlexSearch } from "react-use-flexsearch";
import {
  MessagePreview,
  Pagination,
  SearchBar,
  Seo,
  PageHero,
} from "../components";
import { StaticImage } from "gatsby-plugin-image";
import tw from "twin.macro";

const NoResultWrapper = tw.h3`
mx-auto my-20 py-24 text-center whitespace-pre-line bg-secondary rounded-2xl shadow-one
`;

const MessageListTemplate = ({
  data,
  pageContext: { numPages, currentPage },
}) => {
  const isBrowser = typeof window !== "undefined";
  let messages;
  let searchText = null;

  if (isBrowser) {
    const { search } = window.location;
    searchText = search;
  }

  const query = new URLSearchParams(searchText).get("s");
  const [searchQuery, setSearchQuery] = useState(query || "");

  const { index, store } = data.localSearchMessage;
  const results = useFlexSearch(searchQuery, index, store);

  messages = searchQuery ? results : data.allContentfulMessage.nodes;

  return (
    <>
      <Seo title="Message" description="Sunday service messages" />
      <PageHero
        img={
          <StaticImage
            src={"../images/aaron-burden-pCfJKUB5bes-unsplash.jpg"}
            alt="An bible left open on a table with a blurred background"
            placeholder="blurred"
            layout="constrained"
            width={3840}
            height={2160}
            loading="eager"
            className="hero-img"
          />
        }
        title="Messages"
        text="Browse through our past Sunday messages."
        search={
          isBrowser ? (
            <SearchBar
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
          ) : null
        }
      />

      <div className="page-main">
        {messages.length !== 0 ? (
          messages.map(
            ({ id, slug, title, keyVerseRef, keyVerseQuote, deliveredOn }) => {
              return (
                <Link to={`/message/${slug}`} key={id}>
                  <MessagePreview
                    title={title}
                    keyVerseRef={keyVerseRef ? keyVerseRef : "Key verse number"}
                    keyVerseQuote={
                      keyVerseQuote
                        ? keyVerseQuote.keyVerseQuote
                        : "Key verse quote"
                    }
                    date={deliveredOn}
                  />
                </Link>
              );
            }
          )
        ) : (
          <NoResultWrapper>Try another word...</NoResultWrapper>
        )}
      </div>
      {isBrowser && searchQuery ? null : (
        <Pagination {...{ target: "message", numPages, currentPage }} />
      )}
    </>
  );
};

export const query = graphql`
  query messageListQuery($skip: Int!, $limit: Int!) {
    localSearchMessage {
      index
      store
    }
    allContentfulMessage(
      limit: $limit
      skip: $skip
      sort: { fields: deliveredOn, order: DESC }
    ) {
      nodes {
        id
        slug
        title
        keyVerseRef
        keyVerseQuote {
          keyVerseQuote
        }
        author
        deliveredOn(formatString: "MMMM Do, YYYY")
      }
    }
  }
`;

export default MessageListTemplate;
